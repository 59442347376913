import React from "react";
import { ChatBoxWidget } from "./components/chat-box-widget";
import { createRoot } from "react-dom/client";

const rootContainer = document.createElement("div");
rootContainer.setAttribute("id", "eurostar-chatbot");
rootContainer.setAttribute("style", "position: absolute; z-index: 1000;");
rootContainer.setAttribute("role", "region");

const body = document.getElementsByTagName("body").item(0);

body.appendChild(rootContainer);

const root = createRoot(rootContainer);
root.render(<ChatBoxWidget />);
