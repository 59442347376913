import React from "react";
import { LocaleContextWrapper } from "../context/locale/locale-context";
import { ChatbotExpandable } from "./chat-bot-expandable";
import { MessagesContextWrapper } from "../context/messages/messages-context";

export const ChatBoxWidget = () => {
  return (
    <LocaleContextWrapper>
      <MessagesContextWrapper>
        <ChatbotExpandable />
      </MessagesContextWrapper>
    </LocaleContextWrapper>
  );
};
