import { config } from "../config";
import { addWebpageLocationHeader } from '../utils/api';
import {
  ErrorMessage,
  Message,
  PromptPayload,
} from "./streaming-protocol-types";

const backendUrl = `${config.backendEndpoint}/api/agents/default`;

export async function* streamAnswer(
  payload: PromptPayload,
): AsyncGenerator<Message> {
  const response = await fetch(backendUrl, addWebpageLocationHeader({
    method: `POST`,
    headers: {
      "Content-Type": `application/json`,
    },
    body: JSON.stringify(payload),
  }));

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  if (!response.body) {
    throw new Error("Response body is not defined");
  }

  const decoder = new TextDecoder();
  const stream = response.body.getReader();
  let messageLength = 0;
  let buffer = "";

  while (true) {
    let caughtError: ErrorMessage | null = null;

    const result = await stream.read().catch((err) => {
      caughtError = {
        type: "error",
        message: err.message,
      };

      throw err;
    });

    if (caughtError) {
      throw new Error(caughtError.message);
    }

    if (result.value) {
      const textToken = decoder.decode(result.value);
      buffer += textToken;
    }

    while (buffer.length >= 10) {
      if (!messageLength) {
        messageLength = Number(buffer.substring(0, 10));
        buffer = buffer.substring(10, buffer.length);
      }

      if (buffer.length >= messageLength) {
        yield JSON.parse(buffer.substring(0, messageLength));

        buffer = buffer.substring(messageLength, buffer.length);
        messageLength = 0;
      } else {
        break;
      }
    }

    if (result.done) {
      break;
    }
  }
}
