import { TranslationSet } from "../context/locale/translations";

export const createTextInputProps = (translations: TranslationSet) => {
  return {
    styles: {
      text: {
        color: "rgb(28, 28, 26)",
        fontSize: "16px",
        fontFamily: '"ABC Social Regular", Arial, "sans-serif"',
        padding: "12px 16px",
        background: "rgb(255, 255, 255)",
        lineHeight: "24px",
        WebkitFontSmoothing: "antialiased",
        width: "75%",
        "&::placeholder": {
          color: "#1c1c1a",
        },
      },
      container: {
        borderRadius: "4px",
        border: "1px solid #949492",
        width: "90%",
        transition: "border-color 0.3s ease",
        "&:hover": {
          border: "1px solid #1C1C1A",
        },
      },
      focus: {
        border: "1px solid #106DFF",
        outline: "2px solid #106DFF",
      },
    },
    placeholder: {
      text: translations.chat_text_placeholder,
    },
  };
};

export const messageStylesProps = {
  default: {
    shared: {
      bubble: {
        padding: "10px",
        width: "100%",
        maxWidth: "100%",
        "min-height": "20px",
        fontFamily: '"ABC Social Regular", Arial, "sans-serif"',
        fontWeight: "400",
        fontSize: "16px",
      },
    },
    user: {
      bubble: {
        background: "#00286A",
        width: "fit-content",
      },
    },
  },
  loading: {
    bubble: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    },
  },
};

export const chatStyleProps = { height: "100%", width: "100%" };

export const createSubmitButtonStyles = (translations: TranslationSet) => {
  return {
    submit: {
      container: {
        default: {
          backgroundColor: "#FFC305",
          borderRadius: "4px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0)",
          color: "#1C1C1A",
          cursor: "pointer",
          fontFamily: '"ABC Social Bold", Arial, "sans-serif"',
          fontSize: "14px",
          fontWeight: "400",
          padding: "8px 8px",
          textAlign: "center",
          verticalAlign: "middle",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "4px",
        },
        hover: {
          background: "#CC9C04",
        },
        click: {
          background: "#997503",
        },
      },
      text: {
        content: translations.send_button_text,
      },
    },
    stop: {
      container: {
        default: {
          border: "1px solid #7D0552",
          backgroundColor: "#ffffff",
          color: "#7D0552",
          display: "none",
        },
        hover: {
          background: "#64044233",
        },
        focus: {
          border: "1px solid #7D0552",
        },
        click: {
          background: "#58043966",
        },
      },
    },
    disabled: {
      container: {
        default: {
          color: "#646463",
          background: "#E3E3E3",
        },
        hover: {
          cursor: "not-allowed",
        },
      },
    },
  };
};
