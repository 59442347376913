import { v4 } from "uuid";
import { LocalMessage } from "../../services/streaming-protocol-types";
import { TranslationSet } from "../locale/translations";

export const createInitialMessages = (
  translations: TranslationSet,
): LocalMessage[] => {
  return [
    {
      text: translations["chat_message_1"],
      id: v4(),
      role: "chatbot",
      guard_passed: "FAILED",
    },
    {
      text: translations["chat_message_2"],
      id: v4(),
      role: "chatbot",
      guard_passed: "FAILED",
    },
    {
      text: translations["chat_message_3"],
      id: v4(),
      role: "chatbot",
      guard_passed: "FAILED",
    },
    {
      html: `
        <div class="deep-chat-temporary-message">
        <button class="deep-chat-button deep-chat-suggestion-button">${translations["suggestion_1"]}</button>
          <button class="deep-chat-button deep-chat-suggestion-button">${translations["suggestion_2"]}</button>
          <button class="deep-chat-button deep-chat-suggestion-button">${translations["suggestion_3"]}</button>
        </div>`,
      role: "suggestion",
      guard_passed: "FAILED",
      id: v4(),
    },
  ];
};
