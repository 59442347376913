import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createContext } from "react";
import { useLocale } from "../locale/locale-context";
import { getLocalMessages, saveLocalMessages } from "./message-storage";
import {
  GuardPassMessage,
  LocalMessage,
} from "../../services/streaming-protocol-types";
import { createInitialMessages } from "./default-initial-messages";
import { v4 } from "uuid";
import { CONV_EXPIRE_TIME_KEY, EXPIRY_DURATION } from "../../utils/constants";

export type MessageStorage = {
  messages: LocalMessage[];
  conversationId: string;
  updateMessageHtml: (messageId: string, outerHTML: string) => void;
  updateGuardPasses: (guardPasses: GuardPassMessage) => void;
  clearAllMessages: () => void;
  setMessages: (messages: LocalMessage[]) => void;
};

const MessagesContext = createContext(null);

export const useMessageStorage = (): MessageStorage & {
  messagesRef: MutableRefObject<LocalMessage[]>;
} => {
  const context = useContext(MessagesContext);
  const messagesRef = useRef(context.messages);

  useEffect(() => {
    messagesRef.current = context.messages;
  }, [context.messages]);

  return {
    ...context,
    messagesRef,
  };
};

export const MessagesContextWrapper = (props: React.PropsWithChildren) => {
  const locale = useLocale();
  const [expireTimestamp, setExpireTimestamp] = useState(() => {
    return Number(localStorage.getItem(CONV_EXPIRE_TIME_KEY) || "0");
  });
  const [messages, setMessages] = useState(() => {
    return getLocalMessages(locale.translations);
  });
  const [conversationId, setConversationId] = useState(() => {
    return localStorage.getItem("conversationId") || v4();
  });

  useEffect(() => {
    localStorage.setItem("conversationId", conversationId);
  }, [conversationId]),
    useEffect(() => {
      saveLocalMessages(messages);
    }, [messages]);

  useEffect(() => {
    localStorage.setItem(CONV_EXPIRE_TIME_KEY, String(expireTimestamp));

    if (!expireTimestamp) {
      return;
    }

    const timerId = setTimeout(() => {
      setConversationId(v4());
      clearAllMessages();
      setExpireTimestamp(0);
    }, expireTimestamp - Date.now());

    return () => {
      clearTimeout(timerId);
    };
  }, [expireTimestamp]);

  const updateMessageHtml = useCallback(
    (messageId: string, outerHTML: string) => {
      const newMessages = [...messages];

      const targetMessage = newMessages.find(
        (message) => message.id === messageId,
      );

      if (targetMessage) {
        targetMessage.html = outerHTML;
        setMessages([...messages]);
      }
    },
    [messages],
  );

  const clearAllMessages = useCallback(() => {
    setMessages(createInitialMessages(locale.translations));
    setConversationId(v4());
  }, [messages, locale]);

  const updateGuardPasses = useCallback(
    (guardPasses: GuardPassMessage) => {
      guardPasses.messages.forEach((message) => {
        const localMessage = messages.find((localMessage) => {
          return "id" in localMessage && localMessage.id === message.message_id;
        })!;

        if (localMessage) {
          localMessage.guard_passed = message.guard_passed;
          localMessage.signature = message.signature;
          localMessage.timestamp = message.timestamp;
        }
      });

      setMessages(JSON.parse(JSON.stringify(messages)));
    },
    [messages],
  );

  const setMessagesFunc = useCallback(
    (messages: LocalMessage[]) => {
      setExpireTimestamp(EXPIRY_DURATION + Date.now());
      setMessages(messages);
    },
    [messages],
  );

  return (
    <MessagesContext.Provider
      value={{
        messages: messages,
        conversationId: conversationId,
        updateMessageHtml: updateMessageHtml,
        clearAllMessages: clearAllMessages,
        updateGuardPasses: updateGuardPasses,
        setMessages: setMessagesFunc,
      }}
    >
      {props.children}
    </MessagesContext.Provider>
  );
};
