import { TranslationSet } from "./translations";

export const fr_fr_translations: TranslationSet = {
  chat_message_1: "Les réponses fournies dans ce Chatbot sont générées par IA.",
  chat_message_2:
    "En cas de doute sur une réponse, nous vous recommandons de vérifier l'information sur notre site ou auprès de notre service clientèle.",
  chat_message_3:
    "N'hésitez pas à me poser une question et je ferai de mon mieux pour vous aider.",
  suggestion_1: "Gérer ma réservation",
  suggestion_2: "Bagages autorisés",
  suggestion_3: "Mon train a été retardé",
  close_chat_alt: "Fermer le widget de chat Eurostar",
  eurostar_logo_alt: "Logo Eurostar",
  close_eurtostar_button_label: "Fermer le widget de chat Eurostar",
  toggle_chat_label: "Activer ou désactiver le widget de chat Eurostar",
  chat_label: "Widget de chat Eurostar",
  chat_text_placeholder: "Des questions sur Eurostar ?",
  send_button_text: "Envoyer",
  source_text: "Source",
};
