import React from "react";
import { useLocale } from "../context/locale/locale-context";

interface Source {
  article_url: string;
}

interface ResponseMessageProps {
  html: string;
  source: Source | null;
  feedbackInfo: string;
  widgetEndpoint: string;
}

const Feedback: React.FC<{
  source: Source;
  feedbackInfo: string;
  widgetEndpoint: string;
}> = ({ source, feedbackInfo, widgetEndpoint }) => {
  const locale = useLocale();

  return (
    <div className="source-and-feedback" style={{ display: "none" }}>
      <div className="source">
        <a
          href={source.article_url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#666" }}
        >
          <strong>{locale.translations.source_text}</strong>
        </a>
        &nbsp;&#10095;
      </div>
      <div className="feedback">
        <button className="feedback-up" value={feedbackInfo}>
          <img
            className="feedback-img"
            src={`${widgetEndpoint}/img/thumbs-up.svg`}
            alt="feedback-thumbs-up"
          />
        </button>
        <button className="feedback-down" value={feedbackInfo}>
          <img
            className="feedback-img"
            src={`${widgetEndpoint}/img/thumbs-down.svg`}
            alt="feedback-thumbs-down"
          />
        </button>
      </div>
    </div>
  );
};

const ResponseMessage: React.FC<ResponseMessageProps> = (
  props: ResponseMessageProps,
) => {
  return (
    <div className="message">
      <div
        className="text"
        dangerouslySetInnerHTML={{ __html: props.html }}
      ></div>
      {props.html && props.source && props.feedbackInfo && (
        <Feedback
          source={props.source}
          feedbackInfo={props.feedbackInfo}
          widgetEndpoint={props.widgetEndpoint}
        />
      )}
    </div>
  );
};

export default ResponseMessage;
