const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export const ONE_MINUTE = 60 * 1000; // 1 minute in milliseconds

export const EXPIRY_DURATION = TWENTY_FOUR_HOURS;

export const REQUEST_BODY_LIMITS_PROPS = {
  maxMessages: 50,
  totalMessagesMaxCharLength: 4096,
};

export const CONV_EXPIRE_TIME_KEY = "chatbot_expire_conversation_timestamp";
