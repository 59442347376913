import React, { useContext, useState } from "react";
import { createContext } from "react";
import { translations, TranslationSet } from "./translations";

export type Locale = {
  locale: string;
  translations: TranslationSet;
};

const defaultLocale: Locale = {
  locale: "rw-en",
  translations: translations["rw-en"],
};

const LocaleContext = createContext(defaultLocale);

export const useLocale = (): Locale => {
  return useContext<Locale>(LocaleContext);
};

export const LocaleContextWrapper = (props: React.PropsWithChildren) => {
  const [translation] = useState(() => {
    const urlTokens = window.location.pathname.split("/");

    // /be-nl/something into ["", "be-nl", "something"]
    let locale = urlTokens[1];

    // /customer-dashboard/fr/something into ["", "customer-dashboard", "fr", "something"]
    if (locale === "customer-dashboard") {
      locale = urlTokens[2];
    }

    const localeKey =
      Object.keys(translations).find((fullLocale) => {
        return fullLocale.includes(locale);
      }) || defaultLocale.locale;

    const translationSet = translations[localeKey];

    if (translationSet) {
      return {
        locale: localeKey,
        translations: translationSet,
      };
    } else {
      return defaultLocale;
    }
  });

  return (
    <LocaleContext.Provider value={translation}>
      {props.children}
    </LocaleContext.Provider>
  );
};
