export const toggleFeedbackButtonImage = (target: HTMLButtonElement) => {
  const parent = target.parentElement;
  const imgElement = target.querySelector("img");

  if (parent) {
    const darkImg = parent.querySelector('img[src*="-dark"]');
    if (darkImg) {
      const currentSrc = darkImg.getAttribute("src");
      if (currentSrc) {
        darkImg.setAttribute("src", currentSrc.replace("-dark.svg", ".svg"));
      }
    }
  }

  if (imgElement) {
    const currentSrc = imgElement.getAttribute("src");
    if (currentSrc) {
      imgElement.setAttribute("src", currentSrc.replace(".svg", "-dark.svg"));
    }
  }
};
