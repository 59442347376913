import { createInitialMessages } from "./default-initial-messages";
import { TranslationSet } from "../locale/translations";
import { LocalMessage } from "../../services/streaming-protocol-types";

const isLocalMessageList = (messages: any): messages is LocalMessage[] => {
  return Array.isArray(messages) && messages.every(isLocalMessage);
};

const isLocalMessage = (message: any): message is LocalMessage => {
  return (
    message &&
    typeof message["guard_passed"] === "string" &&
    (typeof message["text"] === "string" ||
      typeof message["html"] === "string") &&
    typeof message["role"] === "string" &&
    typeof message["id"] === "string"
  );
};

const hasAnyUserMessages = (messages: LocalMessage[]): boolean => {
  return messages.some((message) => {
    return message.role === "user";
  });
};

export const getLocalMessages = (
  translations: TranslationSet,
): LocalMessage[] => {
  const storedMessages = localStorage.getItem("chatMessages");
  const initialMessages = createInitialMessages(translations);

  try {
    const parsedMessages = JSON.parse(storedMessages);

    if (!isLocalMessageList(parsedMessages)) {
      return initialMessages;
    }

    if (!hasAnyUserMessages(parsedMessages)) {
      return initialMessages;
    }

    return parsedMessages;
  } catch (err) {
    console.warn("Failed to restore messages", err.message);
  }

  return initialMessages;
};

export const saveLocalMessages = (messages: LocalMessage[]): void => {
  localStorage.setItem("chatMessages", JSON.stringify(messages));
};
