import { rw_en_translations } from "./rw-en";
import { fr_fr_translations } from "./fr-fr";
import { de_de_translations } from "./de_de";
import { be_nl_translations } from "./be_nl";
import { nl_nl_translations } from "./nl_nl";

export type TranslationSet = {
  chat_message_1: string;
  chat_message_2: string;
  chat_message_3: string;
  suggestion_1: string;
  suggestion_2: string;
  suggestion_3: string;
  close_chat_alt: string;
  eurostar_logo_alt: string;
  close_eurtostar_button_label: string;
  toggle_chat_label: string;
  chat_label: string;
  chat_text_placeholder: string;
  send_button_text: string;
  source_text: string;
};

export const translations: { [key: string]: TranslationSet } = {
  "rw-en": rw_en_translations,
  "uk-en": rw_en_translations,
  "us-en": rw_en_translations,
  "fr-fr": fr_fr_translations,
  "be-en": rw_en_translations,
  "be-fr": fr_fr_translations,
  "be-nl": be_nl_translations,
  "de-de": de_de_translations,
  "nl-nl": nl_nl_translations,
};
