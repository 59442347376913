import { getCurrentPath } from './getPath';

interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
}

export const addWebpageLocationHeader = (options: FetchOptions = {}): FetchOptions => {
  const headers = {
    ...options.headers,
    'X-Webpage-Location': getCurrentPath(),
  };

  return {
    ...options,
    headers,
  };
};