import { EventToFunction, HTMLClassUtilities } from "deep-chat/dist/types/html";
import { toggleFeedbackButtonImage } from "../utils/handleFeedbackButtonClicked";
import { useMessageStorage } from "../context/messages/messages-context";
import { useCallback } from "react";
import { postFeedback } from "../services/feedback";

export const feedbackButtonStyles = {
  default: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
};

export const feedbackImgStyles = {
  default: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
};

export const useHtmlClassUtilities = (): HTMLClassUtilities => {
  const mesageStorage = useMessageStorage();

  const handleFeedbackButtonClicked = useCallback(
    async ({
      event,
      feedbackThumbs,
    }: {
      event: MouseEvent;
      feedbackThumbs: boolean;
    }) => {
      const target = event.currentTarget as HTMLButtonElement;
      const feedbackInfo = target.value ? JSON.parse(target.value) : null;
      const { traceInfo, id, conversationId } = feedbackInfo;
      const message = target.closest(".message");

      if (!traceInfo?.trace_id) {
        console.error("No trace info found");
        return;
      }

      if (!id) {
        console.error("No message id found");
        return;
      }

      if (!message) {
        console.error("No message found");
        return;
      }

      const { trace_id, span_id } = traceInfo;

      try {
        await postFeedback({
          traceInfo: {
            trace_id,
            span_id,
          },
          feedbackThumbs,
          messageId: id,
          conversationId,
        });
        toggleFeedbackButtonImage(target);

        mesageStorage.updateMessageHtml(id, message.outerHTML);
      } catch (error) {
        console.error("Failed to post feedback:", error);
      }
    },
    [mesageStorage],
  );

  return {
    ["deep-chat-suggestion-button"]: {
      styles: {
        default: {
          width: "100%",
          padding: "10px",
          marginTop: "5px",
          backgroundColor: "#fff",
          fontFamily: '"ABC Social Regular", Arial, "sans-serif"',
          fontWeight: "400",
          fontSize: "14px",
        },
        hover: {
          backgroundColor: "#e4e6eb",
        },
      },
    },
    ["suggestion-message"]: {
      styles: {
        default: {
          backgroundColor: "#fff",
        },
      },
    },
    ["source-and-feedback"]: {
      styles: {
        default: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #ccc",
          marginTop: "20px",
        },
      },
    },
    ["source"]: {
      styles: {
        default: {
          display: "flex",
          alignItems: "left",
          justifyContent: "left",
          color: "#666",
          fontSize: "0.8em",
          marginTop: "10px",
        },
      },
    },
    ["feedback"]: {
      styles: {
        default: {
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginTop: "10px",
        },
      },
    },
    ["feedback-img"]: {
      styles: feedbackImgStyles,
    },
    ["feedback-up"]: {
      styles: feedbackButtonStyles,
      events: {
        click: (event: MouseEvent) => {
          handleFeedbackButtonClicked({ event, feedbackThumbs: true });
        },
      } as EventToFunction,
    },
    ["feedback-down"]: {
      styles: {
        ...feedbackButtonStyles,
        default: {
          ...feedbackButtonStyles.default,
          marginRight: "0",
        },
      },
      events: {
        click: (event: MouseEvent) => {
          handleFeedbackButtonClicked({ event, feedbackThumbs: false });
        },
      } as EventToFunction,
    },
  };
};
